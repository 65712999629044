<template>
  <div v-html="resolvedRichText" class="richtext" />
</template>

<script lang="ts" setup>
const props = defineProps<{
  richtext: ISbRichtext;
}>();

const resolvedRichText = computed(() => renderRichText(props.richtext));
</script>

<style scoped>
.richtext {
  display: flex;
  flex-direction: column;
  max-width: 75ch;
  width: 100%;
  margin: auto;
  @apply text-lg;
}

.richtext :deep(p) {
  @apply pt-6;
}

.richtext :deep(ul>li>p) {
  @apply pt-1;
}


.richtext :deep(h2) {
  @apply pt-16;
}

.richtext :deep(h3) {
  @apply pb-2 pt-4;
}

.richtext :deep(h4),
.richtext :deep(h5),
.richtext :deep(h6) {
  @apply pb-1 pt-3 text-base;
}

.richtext :deep(blockquote) {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}

.richtext :deep(img) {
  background-color: var(--foreground);
  box-shadow: var(--box-shadow);
  outline: 1px solid var(--seperator-colour);
  @apply h-auto rounded-lg;
}

.richtext :deep(h2 > img) {
  width: 90%;
  margin: 0 auto 1rem auto;
}

.richtext :deep(li > p > img) {
  width: 65%;
}

.richtext :deep(li > p:has(img)) {
  display: flex;
  gap: .25rem;
  flex-direction: column;
  align-items: center;
  @apply pt-5;
}

.richtext :deep(p:has(img)) {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.richtext :deep(ul) {
  display: flex;
  flex-direction: column;
  padding-left: 2.5rem;
}

.richtext :deep(ul > li > p > span) {
  display: list-item;
  list-style-type: disc;
}
</style>
